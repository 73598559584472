import Vue from 'vue';
import Router from 'vue-router';
import store from '@/core/services/store';
import axios from 'axios';

import { hasAccessToPageForCurrentCompany } from '@/core/services/companyAccess';


Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/ml-dashboard',
    component: () => import('@/view/layout/Layout'),
    children: [
      {
        path: '/ml-dashboard',
        name: 'ml-dashboard',
        component: () => import('@/view/pages/ml/Dashboard.vue')
      },
      {
        path: '/ml-dashboard-instances',
        name: 'ml-dashboard-instances',
        component: () => import('@/view/pages/ml/DashboardInstances.vue')
      },
      {
        path: '/ml-renew-guide',
        name: 'ml-renew-guide',
        component: () => import('@/view/pages/ml/RenewGuidePage.vue')
      },
      {
        path: '/ml-history',
        name: 'ml-history',
        component: () => import('@/view/pages/ml/History.vue')
      },
      {
        path: '/ml-sendout',
        name: 'ml-sendout',
        component: () => import('@/view/pages/ml/Sendout.vue')
      },
      {
        path: '/ml-sms',
        name: 'ml-sms',
        component: () => import('@/view/pages/ml/SMS.vue')
      },
      {
        path: '/ml-member-registrations',
        name: 'ml-member-registrations',
        component: () => import('@/view/pages/ml/MemberRegistrations.vue')
      },
      {
        path: '/ml-sendout/:id',
        name: 'ml-sendout-id',
        component: () => import('@/view/pages/ml/Sendout.vue')
      },
      {
        path: '/ml-todo-wizard/:id',
        name: 'ml-todo-wizard',
        component: () => import('@/view/pages/ml/TodoWizard.vue')
      },
      {
        path: '/ml-grant-wizard/:id',
        name: 'ml-grant-wizard',
        component: () => import('@/view/pages/ml/grantwizard/GrantWizard.vue')
      },
      {
        path: '/ml-templates',
        name: 'ml-templates',
        component: () => import('@/view/pages/ml/Templates.vue')
      },
      {
        path: '/ml-templates/:sendout_id/:is_edit/:template_id',
        name: 'ml-templates-sendout',
        component: () => import('@/view/pages/ml/Templates.vue')
      },
      {
        path: '/ml-stickprov',
        name: 'ml-stickprov',
        component: () => import('@/view/pages/ml/Stickprov.vue')
      },
      {
        path: '/ml-files',
        name: 'ml-files',
        component: () => import('@/view/pages/ml/Files.vue')
      },
      {
        path: '/ml-shopitem',
        name: 'ml-shopitem',
        component: () => import('@/view/pages/ml/ShopItem.vue')
      },
      {
        path: '/ml-properties',
        name: 'ml-properties',
        component: () => import('@/view/pages/ml/PropertyGroupsPage.vue')
      },
      {
        path: '/ml-onboarding',
        name: 'ml-onboarding',
        component: () => import('@/view/pages/ml/OnboardingPage.vue')
      },
      {
        path: '/ml-mucf',
        name: 'ml-mucf',
        component: () => import('@/view/pages/ml/MUCF.vue')
      },
      {
        path: '/ml-companies',
        name: 'ml-companies',
        component: () => import('@/view/pages/ml/Companies.vue')
      },
      {
        path: '/ml-errors',
        name: 'ml-errors',
        component: () => import('@/view/pages/ml/Errors.vue')
      },
      {
        path: '/ml-settings/:id',
        name: 'ml-settings-id',
        component: () => import('@/view/pages/ml/Settings.vue')
      },
      {
        path: '/ml-periods',
        name: 'ml-periods',
        component: () => import('@/view/pages/ml/Periods.vue')
      },
      {
        path: '/ml-system',
        name: 'ml-system',
        component: () => import('@/view/pages/ml/System.vue')
      },
      {
        path: '/ml-transactions',
        name: 'ml-transactions',
        component: () => import('@/view/pages/ml/Transactions.vue')
      },
      
      {
        path: '/ml-invoices',
        name: 'ml-invoices',
        component: () => import('@/view/pages/ml/Invoices.vue')
      },
      {
        path: '/ml-jobs',
        name: 'ml-jobs',
        component: () => import('@/view/pages/ml/Jobs.vue')
      },
      {
        path: '/ml-redeem-codes',
        name: 'ml-redeem-codes',
        component: () => import('@/view/pages/ml/RedeemCode.vue')
      },
      {
        path: '/ml-reports',
        name: 'ml-reports',
        component: () => import('@/view/pages/ml/Reports.vue')
      },
      {
        path: '/ml-profile',
        name: 'ml-profile',
        component: () => import('@/view/pages/ml/UserProfile.vue')
      },
      {
        path: '/ml-apikeys',
        name: 'ml-apikeys',
        component: () => import('@/view/pages/ml/APIKeys.vue')
      },
      {
        path: '/ml-redoc',
        name: 'ml-redoc',
        component: () => import('@/view/pages/ml/Redoc.vue')
      },
      {
        path: '/ml-subcompany',
        name: 'ml-subcompany',
        component: () => import('@/view/pages/ml/Subcompany.vue')
      },
      {
        path: '/',
        component: () => import('@/view/layout/Vuetify.vue'),
        children: [
          {
            path: '/ml-forms',
            name: 'ml-forms',
            component: () => import('@/view/pages/ml/Forms.vue')
          },
          {
            path: '/ml-test',
            name: 'ml-test',
            component: () => import('@/view/pages/ml/Test.vue')
          },
          {
            path: '/ml-registersetup',
            name: 'ml-registersetup',
            component: () => import('@/view/pages/ml/RegistrationSetup.vue')
          },
          {
            path: '/ml-events',
            name: 'ml-events',
            component: () => import('@/view/pages/ml/Events.vue')
          },    
          {
            path: '/ml-faith',
            name: 'ml-faith',
            component: () => import('@/view/pages/ml/Faith.vue')
          },
          {
            path: '/ml-swish-merchant',
            name: 'ml-swish-merchant',
            component: () => import('@/view/pages/ml/SwishMerchant.vue')
          },
          {
            path: '/ml-members-control',
            name: 'ml-members-control',
            component: () => import('@/view/pages/ml/MembersControlPage.vue')
          },
          {
            path: '/ml-member-editor',
            name: 'ml-member-editor',
            component: () => import('@/view/pages/ml/MemberEditorPage.vue')
          },
          {
            path: '/ml-member-editor/:member_id',
            name: 'ml-member-editor-id',
            component: () => import('@/view/pages/ml/MemberEditorPage.vue')
          },
          {
            path: '/scan-qr-member/:token',
            name: 'scan-qr-member',
            component: () => import('@/view/pages/ml/auth/ScanQrMember.vue')
          },
          {
            path: '/ml-example',
            name: 'ml-example',
            component: () => import('@/view/pages/ml/ExamplePage.vue')
          },
          {
            path: '/ml-users',
            name: 'ml-users',
            component: () => import('@/view/pages/ml/Users.vue')
          },
          {
            path: '/ml-users/:id',
            name: 'ml-users-id',
            component: () => import('@/view/pages/ml/Users.vue')
          },
          {
            path: '/ml-import',
            name: 'ml-import',
            component: () => import('@/view/pages/ml/ImportPage.vue')
          },
          {
            path: '/ml-importcompanies',
            name: 'ml-importcompanies',
            component: () => import('@/view/pages/ml/ImportCompanies.vue')
          },
          {
            path: '/ml-ladok',
            name: 'ml-ladok',
            component: () => import('@/view/pages/ml/LADOK.vue')
          },    
          {
            path: '/ml-advanced-search',
            name: 'ml-advanced-search',
            component: () => import('@/view/pages/ml/AdvancedSearch.vue')
          },
          {
            path: '/ml-spar',
            name: 'ml-spar',
            component: () => import('@/view/pages/ml/SPAR.vue')
          },
          {
            path: '/ml-settings',
            name: 'ml-settings',
            component: () => import('@/view/pages/ml/Settings.vue')
          },
          { // deprecated name for RenewPage
            path: '/ml-member-manager',
            name: 'ml-member-manager',
            component: () => import('@/view/pages/ml/RenewPage.vue')
          },
          {
            path: '/ml-renew-page',
            name: 'ml-renew-page',
            component: () => import('@/view/pages/ml/RenewPage.vue')
          },
          {
            path: '/ml-payments',
            name: 'ml-payments',
            component: () => import('@/view/pages/ml/ShopOrder.vue')
          },     
          {
            path: '/ml-page',
            name: 'ml-page',
            component: () => import('@/view/pages/ml/Page.vue')
          },
          
          {
            path: '/ml-ths-logs',
            name: 'ml-ths-logs',
            component: () => import('@/view/pages/ml/LogsPage.vue')
          },
          {
            path: '/ml-ths-instances',
            name: 'ml-ths-instances',
            component: () => import('@/view/pages/ml/ths/Instances.vue')
          },
          {
            path: '/ml-grant',
            name: 'ml-grant',
            component: () => import('@/view/pages/ml/Grant.vue')
          },
          
          {
            path: '/ml-todo',
            name: 'ml-todo',
            component: () => import('@/view/pages/ml/Todo.vue')
          },
          {
            path: '/ml-duplicates',
            name: 'ml-duplicates',
            component: () => import('@/view/pages/ml/Duplicates.vue')
          },
          {
            path: '/ml-billing',
            name: 'ml-billing',
            component: () => import('@/view/pages/ml/Billing.vue')
          },
          {
            path: '/ml-book',
            name: 'ml-book',
            component: () => import('@/view/pages/ml/Book.vue')
          },
          {
            path: '/ml-fortnox-invoices',
            name: 'ml-fortnox-invoices',
            component: () => import('@/view/pages/ml/FortnoxInvoices.vue')
          },
          {
            path: '/ml-education',
            name: 'ml-education',
            component: () => import('@/view/pages/ml/Education.vue')
          },
          {
            path: '/ml-support-admin',
            name: 'ml-support-admin',
            component: () => import('@/view/pages/ml/SupportAdminPage.vue')
          },
          {
            path: '/ml-company-billing',
            name: 'ml-company-billing',
            component: () => import('@/view/pages/ml/CompanyBillingPage.vue')
          }
        ]
      },
      
    ]
  },
  {
    path: '/',
    component: () => import('@/view/pages/ml/auth/Auth.vue'),
    children: [
      {
        name: 'privacy-policy',
        path: '/privacy-policy',
        component: () => import('@/view/pages/ml/auth/PrivacyPolicy.vue')
      },
      {
        name: 'terms',
        path: '/terms',
        component: () => import('@/view/pages/ml/auth/Terms.vue')
      },
      {
        name: 'contact',
        path: '/contact',
        component: () => import('@/view/pages/ml/auth/Contact.vue')
      },
      {
        name: 'ml-main-login-no-company',
        path: '/ml-main-login',
        component: () => import('@/view/pages/ml/auth/TabbedLogin.vue')
      },
      {
        name: 'ml-main-login-company',
        path: '/ml-main-login/:company_id',
        component: () => import('@/view/pages/ml/auth/TabbedLogin.vue')
      },
      {
        name: 'ml-bankid-return',
        path: '/ml-bankid-return',
        component: () => import('@/view/pages/ml/auth/LoginBankIdReturn.vue')
      },
      {
        name: 'ml-login-override',
        path: '/ml-login-override',
        component: () => import('@/view/pages/ml/auth/LoginOverride.vue')
      },
      {
        name: 'ml-auth-forward',
        path: '/ml-auth-forward',
        component: () => import('@/view/pages/ml/auth/AuthForward.vue')
      },
      {
        name: 'checkin',
        path: '/checkin/:token',
        component: () => import('@/view/pages/ml/auth/EventCheckin.vue')
      },
      {
        name: 'ml-tokenlogin',
        path: 'ml-tokenlogin/:token',
        component: () => import('@/view/pages/ml/auth/LoginToken.vue')
      },
      {
        name: 'ml-tokenlogin-asterix',
        path: 'ml-tokenlogin/:token/*',
        component: () => import('@/view/pages/ml/auth/LoginToken.vue')
      },
      {
        name: 'ml-activate',
        path: '/ml-activate/:user_id/:token',
        component: () => import('@/view/pages/ml/auth/Activate.vue')
      },
      {
        name: 'ml-register',
        path: '/ml-register/:company_id',
        component: () => import('@/view/pages/ml/auth/Register.vue')
      },
      {
        name: 'ml-forget',
        path: '/ml-forget',
        component: () => import('@/view/pages/ml/auth/Forget.vue')
      },
      {
        name: 'ml-reset',
        path: '/ml-reset/:token',
        component: () => import('@/view/pages/ml/auth/Reset.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/view/pages/members/register/Layout.vue'),
    children: [
      {
        name: 'register',
        path: '/register/:id',
        component: () => import('@/view/pages/members/register/RegisterWizard.vue')
      },
      {
        name: 'bankid-verify-return',
        path: '/bankid-verify-return/:token',
        component: () => import('@/view/pages/members/register/bankid/VerifyWithBankIdPage.vue')
      },
      {
        name: 'bankid-verify-member',
        path: '/bankid-verify-member',
        component: () => import('@/view/pages/members/register/bankid/VerifyWithBankIdPage.vue')
      },
      {
        name: 'renew-wizard',
        path: '/renew-wizard/:company_id/:member_id/:token',
        component: () => import('@/view/pages/members/register/RenewWizard.vue')
      },
      {
        name: 'renew-confirm',
        path: '/renew-confirm/:member_id/:renew_id/:token',
        component: () => import('@/view/pages/members/register/RenewConfirm.vue')
      },
      {
        name: 'register-single',
        path: '/register-single/:id',
        component: () => import('@/view/pages/members/register/SinglePage.vue')
      },
      {
        name: 'register-single-luf',
        path: '/register-single-luf/:id',
        component: () => import('@/view/pages/members/register/SinglePage.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/view/pages/members/loggedin/Layout.vue'),
    children: [
      {
        name: 'tokenlogin',
        path: '/tokenlogin',
        component: () => import('@/view/pages/members/loggedin/TokenLogin.vue')
      },
      {
        name: 'profile',
        path: '/profile',
        component: () => import('@/view/pages/members/loggedin/Profile.vue')
      },
      {
        name: 'profile-client_id',
        path: '/profile/:client_id',
        component: () => import('@/view/pages/members/loggedin/Profile.vue')
      },
      {
        name: 'profile-client-token',
        path: '/profile/:client_id/:token',
        component: () => import('@/view/pages/members/loggedin/Profile.vue')
      },
      {
        name: 'renew',
        path: '/renew/:token',
        component: () => import('@/view/pages/members/loggedin/MemberRenew.vue')
      },
      {
        name: 'event',
        path: '/event/:id',
        component: () => import('@/view/pages/members/public/EventDetails.vue')
      },
      {
        name: 'events',
        path: '/events/:id',
        component: () => import('@/view/pages/members/public/Events.vue')
      },
      {
        name: 'category',
        path: '/category/:id',
        component: () => import('@/view/pages/members/public/EventCategory.vue')
      },
      
    ]
  },
  {
    path: '/',
    component: () => import('@/view/pages/members/auth/Auth.vue'),
    children: [
      {
        name: 'v2-tabbed-login-member',
        path: '/v2/login/:company_id',
        component: () => import('@/view/pages/members/auth/login/MemberTabbedLogin.vue')
      },
      {
        name: 'v2-member-bankid-return',
        path: '/v2/member-bankid-return/:token',
        component: () => import('@/view/pages/members/auth/login/MemberLoginBankIdReturn.vue')
      },
      {
        name: 'login-company',
        path: '/login/:company_id',
        component: () => import('@/view/pages/members/auth/Login.vue')
      },
      {
        name: 'activate',
        path: '/activate/:client_id/:token',
        component: () => import('@/view/pages/members/auth/Activate.vue')
      },
      // {
      //   name: 'confirm',
      //   path: '/confirm/:client_id/:code',
      //   component: () => import('@/view/pages/members/auth/Confirm.vue')
      // },
      {
        name: 'forget-company',
        path: '/forget/:company_id',
        component: () => import('@/view/pages/members/auth/Forget.vue')
      },
      {
        name: 'forget',
        path: '/forget',
        component: () => import('@/view/pages/members/auth/Forget.vue')
      },
      {
        name: 'reset',
        path: '/reset/:token',
        component: () => import('@/view/pages/members/auth/Reset.vue')
      },
    ]
  },
  {
    name: 'shortlink',
    path: '/x/:linkstr',
    component: () => import('@/view/pages/members/public/ShortLink.vue')
  },
  {
    name: 'pdfinvoice',
    path: '/pdf/:linkstr',
    component: () => import('@/view/pages/members/public/PdfLink.vue')
  },
  {
    name: 'resume-payment-session',
    path: '/resume-payment-session/:shop_order_id/:token',
    component: () => import('@/view/pages/members/payment/ResumePaymentSession.vue')
  },
  {
    name: 'payment-accept',
    path: '/payment-accept',
    component: () => import('@/view/pages/members/payment/PaymentAccept.vue')
  },
  {
    name: 'payment-callback',
    path: '/payment-callback',
    component: () => import('@/view/pages/members/payment/PaymentCallback.vue')
  },
  {
    name: 'payment-return/stripe',
    path: '/payment-return/stripe',
    component: () => import('@/view/pages/members/payment/PaymentReturn.vue')
  },
  {
    name: 'payment',
    path: '/payment/:shop_order_id/:dintero_id/:token',
    component: () => import('@/view/pages/members/payment/PaymentPage.vue')
  },
  {
    name: 'stripe-payment',
    path: '/stripe-payment/:member_id',
    component: () => import('@/view/pages/members/payment/StripePaymentPage.vue')
  },
  {
    name: 'select-payment',
    path: '/select-payment/:token/:shop_order_id',
    component: () => import('@/view/pages/members/payment/SelectPaymentPage.vue')
  },
  {
    name: 'return-all-payment',
    path: '/return-all-payment/:token/:shop_order_id',
    component: () => import('@/view/pages/members/payment/ReturnAllPaymentsPage.vue')
  },
  {
    name: 'event-payment',
    path: '/event-payment/:shop_order_id/:dintero_id/:token',
    component: () => import('@/view/pages/members/payment/PaymentEventPage.vue')
  },
  {
    name: 'renewpayment',
    path: '/renewpayment/:renew_id/:dintero_id/:token',
    component: () => import('@/view/pages/members/payment/PaymentPage.vue')
  },
  {
    name: 'spurl',
    path: '/spurl/:id',
    component: () => import('@/view/pages/members/public/Stickprov.vue')
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/ml-login',
    name: 'ml-login',
    component: () => import('@/view/pages/Error.vue')
  },
  {
    path: '/ml-no-access-login/:company_id',
    name: 'ml-no-access-login',
    component: () => import('@/view/pages/NoAccessError.vue')
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/view/pages/Error.vue')
  },
  {
    path: '/fortnox-redirect/:authorization-code',
    name: 'fortnox-redirect-code',
    component: () => import('@/view/pages/api/FortnoxAuthenticate.vue')
  },
  {
    path: '/fortnox-redirect',
    name: 'fortnox-redirect',
    component: () => import('@/view/pages/api/FortnoxAuthenticate.vue')
  },
  {
    path: '/',
    component: () => import('@/view/layout/Vuetify.vue'),
    children: [
      {
        name: 'InquiryCompanyForm',
        path: '/inquiry',
        component: () => import('@/view/pages/public/InquiryCompanyForm.vue')
      },
      {
        name: 'PublicForm',
        path: '/form/:linkstr',
        component: () => import('@/view/pages/public/forms/FormIndex.vue')
      }
    ]
  }
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});


router.beforeEach(async (to, from, next) => {
  
  const dest = to.path.replace("/", "");

  const is_user_authenticated = store.getters.isAuthenticated;
  const is_member_authenticated = store.getters.isMemAuthenticated;

  let stripped = dest.split('/')[0];

  console.log('dest', dest);
  console.log('stripped', stripped);
  console.log('is_user_authenticated', is_user_authenticated);
  console.log('is_member_authenticated', is_member_authenticated);

  console.log('sid', store.getters.sid);

  if (is_member_authenticated) {
    if (dest.substring(0,3) !== 'ml-') {
      return next();
    }
  }

  if (is_user_authenticated) {

    if (store.getters.need_terms) {
      if (stripped !== 'ml-auth-forward') {
        router.push({ name: 'ml-auth-forward' });
        return;
      }
      
      return next();
    }

    if (stripped === '' || stripped === 'ml-main-login' || stripped === 'ml-login') {
      router.push({ name: 'ml-dashboard' });
      return;
    }
  }
  else {

    console.log('checking stripped', stripped);

    switch (stripped) {
      case 'ml-login':
      {
        const settings = await axios.get(`/system/public`);

        router.push(`/ml-main-login/${settings.data.company_id}`);
        return;
      }
      case 'ml-forget':
      case 'v2':
      case 'ml-reset':
      case 'ml-activate':
      case 'ml-main-login':
      case 'ml-bankid-return':
      case 'member-bankid-return':
      case 'bankid-verify-return':
      case 'bankid-verify-member':
      case 'ml-register':
      case 'ml-login-override':
      case 'ml-tokenlogin':
      case 'ml-auth-forward':
      case 'ml-no-access-login':
      case 'login':
      case 'pdf':
      case 'forget':
      case 'intresse':
      case 'inquiry':
      case 'form':
      case 'fortnox-redirect':
      case 'event-payment':
      case 'event':
      case 'events':
      case 'category':
      case 'payment':
      case 'payment-return':
      case 'payment-accept':
      case 'payment-callback':
      case 'profile-client-token':
      case 'signup-id':
      case 'shortlink':
      case 'spurl':
      case 'signup':
      case 'stripe':
      case 'stripe-payment':
      case 'swish-payment':
      case 'renew-wizard':
      case 'renew-confirm':
      case 'register-single':
      case 'renew':
      case 'resume-payment-session':
      case 'return-all-payment':
      case 'renewpayment':
      case 'reset':
      case 'register':
      case 'select-payment':
      case 'x':
      case '404':
        return next();
    }

    if (store.getters.mlogin_link) {
      location.href = store.getters.mlogin_link;
    }
    else if (store.getters.ulogin_link) {
      location.href = store.getters.ulogin_link;
    }

    try {
      const settings = await axios.get(`/system/public`);

      if (settings.status === 200 && settings.data.redirect_no_auth && settings.data.redirect_no_auth !== '' && settings.data.redirect_no_auth !== '/') {
        console.log('settings redirect to', settings.data.redirect_no_auth);

        location.href = settings.data.redirect_no_auth;
      }
      else if (settings.data.company_id) {
        console.log('settings redirect to', `/ml-main-login/${settings.data.company_id}`);

        location.href = `/ml-main-login/${settings.data.company_id}`;
      }
      else {
        location.href = '/404';
        return;
      }
      
    }
    catch (err) {
      console.error('router catch error', err);

      location.href = '/404';
      return;
    }
    
  }
  

  if (dest.substring(0,3) === 'ml-' && !hasAccess(dest)) {
    
    const company_id = store.getters['sid'];

    console.error('hasAccess is false, redirecting to ml-no-access-login with company_id ', company_id);

    location.href = `/ml-no-access-login/${company_id}`;
  }
  else {
    return next();
  }
});


router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    var host = window.location.host;

    document.title = host.split('.')[0].toUpperCase() + ' Memlist';
  });
});

const hasAccess = (name) => {

  const has_access = hasAccessToPageForCurrentCompany(name);
  
  return has_access;
};

export default router;
